<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12">
        <h1>All Quotations</h1>
        <v-simple-table
          fixed-header
          height="450px"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Status</th>
                <th class="text-left">No</th>
                <th class="text-left">Customer</th>
                <th class="text-left">Date</th>
                <th class="text-left">Site</th>
                <th class="text-left">Value</th>
                <th class="text-left">By</th>
                <th class="text-left" style="width:130px">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(quotation, index) in quotations"
                :key="quotation.id"
              >
                <td>{{ quotation.statusLetter }}</td>
                <td>{{ quotation.jobNumber }} {{ quotation.expired }}</td>
                <router-link :to="`/customers/view/${quotation.customerId}`">
                  {{ quotation.customer }}
                </router-link>
                <td>{{ quotation.date | tinyDate }}</td>
                <td>{{ quotation.site }}</td>
                <td>
                  <span v-if="quotation.value > quotation.minChargeAmount">
                    {{ quotation.value | priceInPounds | currency }}
                  </span>
                  <span v-else>
                    {{ quotation.minChargeAmount | priceInPounds | currency }}
                  </span>
                </td>
                <td>{{ quotation.quotedByInitials }}</td>
                <td class="pr-0">
                  <router-link custom v-slot="{ navigate }" :to="`/quotations/view/${quotation.id}`">
                    <v-btn
                      title="View"
                      x-small
                      @click="navigate"
                      @keypress.enter="navigate"
                      role="link">
                        View
                    </v-btn>
                  </router-link>
                  &nbsp;
                  <v-btn
                    @click="authoriseQuotation(index)"
                    v-if="showAuthorise && quotation.requiresAuth"
                    x-small>Auth</v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-pagination
          class="pt-4"
          v-model="page"
          :length="length"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          :total-visible="totalVisible"
        ></v-pagination>
      </v-col>
    </v-row>
    <router-view></router-view>
  </v-container>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'QuotationsIndex',
  computed: {
    token() {
      return this.$store.state.token;
    },
    userid() {
        return this.$store.state.userid;
    },
    showAuthorise() {
      let showAuth = false;
      for (let i = 0; i < this.authorisers.length; i += 1) {
        if (this.authorisers[i].user_id === this.userid) showAuth = true;
      }
      return showAuth;
    },
  },
  data() {
    return {
      authorisers: [],
      quotations: [],
      page: 1,
      length: 0,
      totalVisible: 9,
      orderConverted: 0,
    };
  },
  watch: {
    page() {
      this.getQuotations();
    },
  },
  methods: {
    authoriseQuotation(index) {
      const postData = {};
      postData.quotation = this.quotations[index];
      postData.user = this.userid;
      axios.post(`/quotations/authorise.json?token=${this.token}`, postData)
        .then(() => {
          this.quotations[index].requiresAuth = 0;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    reorderConverted() {
      const orderConverted = this.orderConverted;
      if (orderConverted === 0) this.orderConverted = 1;
      if (orderConverted === 2) this.orderConverted = 1;
      if (orderConverted === 1) this.orderConverted = 2;
      this.page = 1;
      this.getQuotations();
    },
    getQuotations() {
      axios.get(`/quotations/getAll/${this.page}/${this.orderConverted}.json?token=${this.token}`)
        .then((response) => {
          this.quotations = response.data.quotations;
          this.length = response.data.length;
          this.total = response.data.total;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAuthorisers() {
      axios.get(`/authorisers/getAll.json?token=${this.token}`)
        .then((response) => { 
          this.authorisers = response.data.quoteAuthorisers;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getAuthorisers();
    this.getQuotations();
  },
};
</script>
